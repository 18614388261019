import { ChevronDownIcon } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { useTranslation } from 'react-i18next';

import NotificationStoreContext from '@/context/NotificationStoreContext';
import User from '@/domain/core/User';
import { TNotificationStore } from '@/domain/notification/NotificationStore';
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuTrigger,
} from '@/ui/DropdownMenu';
import { Text } from '@/ui/Text';
import { Tooltip, TooltipArrow, TooltipContent, TooltipProvider, TooltipTrigger } from '@/ui/Tooltip';
import { cn } from '@/utils';
import { getRole } from '@/utils/words/getRole';

const UserProfile = observer(() => {
	const { t } = useTranslation();
	const currentRole = getRole(User.currentRole);
	const roleDescription = currentRole ? t(`roles.${currentRole}`) : User.role.label || User.role.name || '';

	const [selectedRole, selectRole] = React.useState<number>(() => User.role.id);

	const { getNotificationCountByRole } = React.useContext<TNotificationStore>(NotificationStoreContext);

	return (
		<div className="flex gap-[9px] flex-nowrap max-w-[240px] flex-[0]">
			<TooltipProvider>
				<Tooltip>
					<TooltipTrigger asChild>
						<div>
							<Link href={`/account/${User.id}/profile`} passHref>
								<a>
									<div className="bg-gradient-to-b from-white to-brand-gray-8 text-brand-dark-blue border border-brand-gray-4 p-[6px] rounded-lg cursor-pointer">
										<div className="size-10 flex items-center justify-center relative">
											<Image
												layout="fill"
												objectFit="cover"
												objectPosition="center center"
												src={User?.profile?.photo?.url || '/img/png/no-photo.png'}
												width={24}
												height={24}
												className="size-6 rounded-lg"
											/>
										</div>
									</div>
								</a>
							</Link>
						</div>
					</TooltipTrigger>
					<TooltipContent>
						<TooltipArrow />
						Перейти в профиль
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
			<div className="flex flex-col justify-between gap-1 py-[9px]">
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger asChild>
							<Text
								variant="description"
								overflow="ellipsis"
								className={cn('text-brand-dark line-clamp-2', {
									'whitespace-nowrap': User.availableRoles.length > 1,
								})}
							>
								{roleDescription}
							</Text>
						</TooltipTrigger>
						<TooltipContent>
							<TooltipArrow />
							{roleDescription}
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
				{User.availableRoles.length > 1 && (
					<DropdownMenu>
						<DropdownMenuTrigger>
							<Text variant="labelDescription" className="flex gap-1 text-brand-blue whitespace-nowrap">
								Сменить роль
								<ChevronDownIcon className="w-3 h-3 flex overflow-visible" strokeWidth={3} />
							</Text>
						</DropdownMenuTrigger>
						<DropdownMenuContent>
							<DropdownMenuLabel>Сменить роль в системе</DropdownMenuLabel>
							{User.availableRoles.map((role) => {
								const notificationCount = getNotificationCountByRole(role.id);
								return (
									<DropdownMenuCheckboxItem
										key={role.id}
										checked={selectedRole === role.id}
										onCheckedChange={(checked) => {
											if (checked) {
												User.setCurrentRole(role.id);
												selectRole(role.id);
											}
										}}
										className="max-w-[240px] [&>span]:relative"
									>
										{role.label || role.name}
										{notificationCount > 0 && (
											<span className="absolute -right-3 top-0 z-10 min-w-[18px] h-[18px] flex justify-center items-center rounded-full text-white bg-[#F93333] p-1 text-xs">
												{notificationCount < 100 ? notificationCount : '99+'}
											</span>
										)}
									</DropdownMenuCheckboxItem>
								);
							})}
						</DropdownMenuContent>
					</DropdownMenu>
				)}
			</div>
		</div>
	);
});

export { UserProfile };

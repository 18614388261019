import React, { FC, useMemo } from 'react';

import Footer from '@/components/shared/footer';
import { Header } from '@/components/shared/headers/Header';
import NotAllowedPlatform from '@/components/shared/utillities/NotAllowedPlatform';
import PageHead from '@/components/shared/utillities/PageHead';
import NotificationStoreContext from '@/context/NotificationStoreContext';
import NotificationStore from '@/domain/notification/NotificationStore';
import usePlatformDetect from '@/hooks/usePlatformDetect';

interface MainLayoutProps {
	title: string;
	children: React.ReactNode;
}

const MainLayout: FC = (props: MainLayoutProps) => {
	const { title, children } = props;
	const NotificationStoreInstance = useMemo(() => new NotificationStore(), []);

	const platform = usePlatformDetect();

	if (platform !== 'desktop') {
		return <NotAllowedPlatform />;
	}

	return (
		<>
			<PageHead title={title} />
			<NotificationStoreContext.Provider value={NotificationStoreInstance}>
				<Header />
			</NotificationStoreContext.Provider>
			<section className="content">{children}</section>
			<Footer />
		</>
	);
};

export default MainLayout;

import React from 'react';

import { useControllableState } from '@/hooks/useControllableState';
import usePlatformDetect from '@/hooks/usePlatformDetect';
import { Button } from '@/ui/Button';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '@/ui/Dialog';
import {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from '@/ui/Drawer';
import { ScrollArea } from '@/ui/ScrollArea';
import { cn } from '@/utils';

export interface ModalProps {
	open?: boolean;
	setOpen?: (value: boolean) => void;
	defaultOpen?: boolean;
	title?: React.ReactNode;
	description?: React.ReactNode;
	children?: React.ReactNode;
	trigger?: React.ReactNode;
	footer?: React.ReactNode;
	wrapper?: React.ReactNode;
	closeButton?: React.ReactNode | false;
	disableScrollArea?: boolean;
	drawerContentProps?: React.ComponentProps<typeof DrawerContent>;
	dialogContentProps?: React.ComponentProps<typeof DialogContent>;
	renderWrapper?: (children: React.ReactNode) => React.ReactElement;
	onClose?: () => void;
}

const Modal = (props: ModalProps) => {
	const {
		open,
		setOpen,
		defaultOpen,
		title,
		description,
		trigger,
		dialogContentProps,
		drawerContentProps,
		children,
		disableScrollArea = false,
		footer,
		closeButton,
		renderWrapper = (c) => c,
		onClose,
	} = props;

	const [openValue, setOpenValue] = useControllableState({
		prop: open,
		onChange: setOpen,
		defaultProp: defaultOpen,
	});

	const platform = usePlatformDetect();

	const isDesktop = platform === 'desktop';

	const showHeader = Boolean(title || description);

	if (isDesktop) {
		return (
			<Dialog open={openValue} onOpenChange={setOpenValue}>
				{trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
				<DialogContent
					{...dialogContentProps}
					className={cn(dialogContentProps?.className, {
						'overflow-hidden overflow-y-auto': disableScrollArea,
					})}
				>
					<div
						className={cn('overflow-hidden', {
							'p-1': disableScrollArea,
						})}
					>
						{renderWrapper(
							<>
								{showHeader && (
									<DialogHeader>
										{title && <DialogTitle className="line-clamp-1">{title}</DialogTitle>}
										{description && <DialogDescription>{description}</DialogDescription>}
									</DialogHeader>
								)}
								{children && !disableScrollArea ? (
									<ScrollArea className="max-h-[calc(80vh-285px)] mb-8" viewportClassName="p-1">
										{children}
									</ScrollArea>
								) : (
									children
								)}
								<DialogFooter>
									{footer}
									<DialogClose asChild>
										{closeButton ?? (
											<Button variant="tetriary" onClick={onClose}>
												Отменить
											</Button>
										)}
									</DialogClose>
								</DialogFooter>
							</>
						)}
					</div>
				</DialogContent>
			</Dialog>
		);
	}

	return (
		<Drawer open={openValue} onOpenChange={setOpenValue} dismissible={!!closeButton} onClose={onClose}>
			{trigger && <DrawerTrigger asChild>{trigger}</DrawerTrigger>}
			<DrawerContent {...drawerContentProps}>
				{renderWrapper(
					<>
						{showHeader && (
							<DrawerHeader>
								{title && <DrawerTitle>{title}</DrawerTitle>}
								{description && <DrawerDescription>{description}</DrawerDescription>}
							</DrawerHeader>
						)}
						{children && <div className="overflow-auto px-4">{children}</div>}
						<DrawerFooter>
							{footer}
							<DrawerClose asChild>
								{closeButton ?? (
									<Button variant="tetriary" onClick={onClose}>
										Отменить
									</Button>
								)}
							</DrawerClose>
						</DrawerFooter>
					</>
				)}
			</DrawerContent>
		</Drawer>
	);
};

export { Modal };

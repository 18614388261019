import { observer } from 'mobx-react-lite';
import React from 'react';

import { HeaderMenu } from '@/components/shared/headers/Header/HeaderMenu';
import HeaderStore from '@/domain/core/HeaderStore';
import User from '@/domain/core/User';

import { HeaderLogo } from './HeaderLogo';
import { LanguageSwitcher } from './LanguageSwitcher';
import { LogoutButton } from './LogoutButton';
import { NotificationButton } from './NotificationButton';
import { UserProfile } from './UserProfile';

const Header = observer(() => {
	const HeaderStoreInstance = React.useMemo(() => new HeaderStore(User), []);
	const { tabsConfig } = HeaderStoreInstance;

	return (
		<header className="flex items-center justify-between w-full bg-white shadow h-20 pl-8 space-x-8">
			<HeaderLogo href="/" />
			<HeaderMenu menuItems={tabsConfig} />
			<div className="flex items-center justify-end gap-5">
				<LanguageSwitcher />
				<NotificationButton />
				<UserProfile />
				<LogoutButton />
			</div>
		</header>
	);
});

export { Header };

import React from 'react';

type TSize = 'small' | 'normal';

interface IProps {
	size?: TSize;
}

const SIZES = {
	small: { width: 12, height: 12 },
	normal: { width: 16, height: 16 },
};

const CrossIcon: React.FC<IProps> = ({ size = 'normal' }) => (
	<svg
		width={SIZES[size].width}
		height={SIZES[size].height}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.66699 2.66675L13.3337 13.3334M13.3337 2.66675L2.66699 13.3334"
			stroke="#B6BCC9"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
);
export default CrossIcon;

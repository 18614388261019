import React from 'react';
import { useToggle } from 'react-use';

import NotificationSidebar from '@/components/shared/sidebars/NotificationSidebar';
import NotificationStoreContext from '@/context/NotificationStoreContext';
import { TNotificationStore } from '@/domain/notification/NotificationStore';
import { Icon } from '@/ui/Icon';

const NotificationButton = () => {
	const [isOpen, setOpen] = useToggle(false);
	const { readNotification } = React.useContext<TNotificationStore>(NotificationStoreContext);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
		setTimeout(() => {
			readNotification();
		}, 1500);
	};

	return (
		<div className="cursor-pointer p-2">
			<NotificationSidebar open={isOpen} handleClose={handleClose} handleOpen={handleOpen}>
				<Icon name="notificationBell" className="w-6 h-6 text-brand-gray-3" size={24} />
			</NotificationSidebar>
		</div>
	);
};

export { NotificationButton };

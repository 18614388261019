export {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogNote,
	DialogOverlay,
	DialogPortal,
	DialogTitle,
	DialogTrigger,
} from './Dialog';

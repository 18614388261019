import { useCallback, useRef } from 'react';

export const useDebouncedCallback = (callback: (...args: unknown[]) => void, delay: number) => {
	const timeout = useRef<ReturnType<typeof setTimeout>>(null);

	return useCallback(
		(...args) => {
			const later = () => {
				clearTimeout(timeout.current);
				callback(...(args as unknown[]));
			};

			clearTimeout(timeout.current);
			timeout.current = setTimeout(later, delay);
		},
		[callback, delay]
	);
};

import clsx from 'clsx';
import React, { ReactElement, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Button from '@/components/core/Button';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { useRootElement } from '@/hooks/useRootElement';

import cn from './style.module.sass';

export interface ISidebarModal {
	isOpen: boolean;
	onClickOutside?: () => void;
	onOverlayClick?: () => void;
	onCloseHandler: () => void;
	handleScroll?: () => void;
	customBottomComponent?: ReactElement;
	preventCloseOnClickOutside?: boolean;
	destroyOnClose?: boolean;
}

const SidebarModal: React.FC<ISidebarModal> = ({
	onCloseHandler,
	isOpen,
	children,
	onClickOutside,
	onOverlayClick,
	handleScroll = () => false,
	customBottomComponent,
	preventCloseOnClickOutside = false,
	destroyOnClose = false,
}) => {
	const node = useRootElement('body');
	const { t } = useTranslation();
	const modalRef = useRef(null);

	const onClickOutsideHandler = () => {
		if (onClickOutside) {
			onClickOutside();
			return;
		}

		if (preventCloseOnClickOutside) return;
		onCloseHandler();
	};

	const onOverlayClickHandler = () => {
		if (onOverlayClick) {
			onOverlayClick();
			return;
		}

		onCloseHandler();
	};

	useOnClickOutside(modalRef, onClickOutsideHandler);

	// @todo add css transition
	if (!node) return null;

	return ReactDOM.createPortal(
		<div className={clsx(cn.window, isOpen && cn.active)} ref={modalRef}>
			<div className={cn.wrapper}>
				<div className={cn.content} onScroll={handleScroll}>
					{destroyOnClose && !isOpen ? null : children}
				</div>
				<div className={cn.bottom}>
					{customBottomComponent || (
						<Button data-testid="sidebar-modal-close-button" onClick={onCloseHandler}>
							{t('Закрыть')}
						</Button>
					)}
				</div>
			</div>
			<div className={cn.overlay} onClick={onOverlayClickHandler} role="presentation" />
		</div>,
		node
	);
};

export default SidebarModal;

import { Language, LANGUAGE_COOKIE_KEY, LANGUAGES } from '@workspace/shared-constants';
import { useDebounceEffect } from 'ahooks';
import Cookie from 'js-cookie';
import { GlobeIcon } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuTrigger,
} from '@/ui/DropdownMenu';

const LanguageSwitcher = () => {
	const { i18n } = useTranslation();
	const currentLocale = i18n.language.startsWith(Language.RU) ? Language.RU : Language.EN;

	const [selectedLocale, setSelectedLocale] = React.useState(currentLocale);

	useDebounceEffect(
		() => {
			i18n.changeLanguage(selectedLocale);
			Cookie.set(LANGUAGE_COOKIE_KEY, selectedLocale);
		},
		[selectedLocale],
		{ wait: 150 }
	);

	return (
		<DropdownMenu>
			<DropdownMenuTrigger>
				<div className="flex items-center gap-2 w-12 h-4">
					<div className="text-xs uppercase text-brand-gray-2">{currentLocale}</div>
					<GlobeIcon className="text-brand-gray-3" size={24} />
				</div>
			</DropdownMenuTrigger>
			<DropdownMenuContent>
				<DropdownMenuLabel>Сменить язык в системе</DropdownMenuLabel>
				{LANGUAGES.map((lang) => (
					<DropdownMenuCheckboxItem
						key={lang}
						checked={lang === selectedLocale}
						onCheckedChange={(checked) => {
							if (checked) {
								setSelectedLocale(lang);
							}
						}}
					>
						{i18n.t(`lang.${lang}`)}
					</DropdownMenuCheckboxItem>
				))}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export { LanguageSwitcher };

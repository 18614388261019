import Image from 'next/image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BaseLink from '@/components/core/Link';
import { Text } from '@/ui/Text';

const HeaderLogo = ({ href }: { href: string }) => {
	const { t } = useTranslation();

	return (
		<div className="flex items-center space-x-5 flex-shrink-0">
			<div className="min-w-[120px]">
				<BaseLink href={href}>
					<Image src="/img/svg/logo.svg" width={125} height={80} alt="Logo" />
				</BaseLink>
			</div>
			<div className="w-[200px] py-5">
				<Text variant="header4" className="text-base">
					{t('layout.header.title')}
				</Text>
			</div>
		</div>
	);
};

export { HeaderLogo };

/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';

type UseControllableStateParams<T> = {
	prop?: T | undefined;
	defaultProp?: T | undefined;
	onChange?: (state: T) => void;
};

type SetStateFn<T> = (prevState?: T) => T;

// eslint-disable-next-line @typescript-eslint/no-empty-function
function useControllableState<T>({ prop, defaultProp, onChange = () => {} }: UseControllableStateParams<T>) {
	const [uncontrolledProp, setUncontrolledProp] = useUncontrolledState({ defaultProp, onChange });
	const isControlled = prop !== undefined;
	const value = isControlled ? prop : uncontrolledProp;
	// const handleChange = useCallbackRef(onChange);

	const setValue: React.Dispatch<React.SetStateAction<T | undefined>> = React.useCallback(
		(nextValue) => {
			if (isControlled) {
				const setter = nextValue as SetStateFn<T>;
				const _value = typeof nextValue === 'function' ? setter(prop) : nextValue;
				if (_value !== prop) onChange(_value);
			} else {
				setUncontrolledProp(nextValue);
			}
		},
		[isControlled, prop, setUncontrolledProp, onChange]
	);

	return [value, setValue] as const;
}

function useUncontrolledState<T>({ defaultProp, onChange }: Omit<UseControllableStateParams<T>, 'prop'>) {
	const uncontrolledState = React.useState<T | undefined>(defaultProp);
	const [value] = uncontrolledState;
	const prevValueRef = React.useRef(value);
	// const handleChange = useCallbackRef(onChange);

	React.useEffect(() => {
		if (prevValueRef.current !== value) {
			onChange(value);
			prevValueRef.current = value;
		}
	}, [value, prevValueRef, onChange]);

	return uncontrolledState;
}

export { useControllableState, useUncontrolledState };

import { makeAutoObservable } from 'mobx';
import { io, Socket } from 'socket.io-client';

import User from '@/domain/core/User';
import { TNotification, TNotificationStat } from '@/types/notification';
import { backendApi } from '@/utils/api';
import { SOCKET_URL } from '@/utils/constants/socket';

export type TNotificationStore = {
	notifications: TNotification[];
	stats: TNotificationStat[];
	visibleNotifications: number[];
	visibleNotificationsToSend: number[];
	readonly socket: Socket;
	getNotificationCountByRole: (roleId: number) => number;
	updateNotifications: (notifications: TNotification[]) => void;
	updateVisibleNotifications: (id: number) => void;
	clearVisibleNotificationsToSend: () => void;
	deleteNotification: (id: number) => void;
	readNotification: () => void;
	fetchNotifications: () => void;
	subscribe: () => void;
	notificationCount: number;
};

class NotificationStore implements TNotificationStore {
	notifications: TNotification[] = [];

	stats: TNotificationStat[] = [];

	readonly socket: Socket;

	visibleNotifications: number[] = [];

	visibleNotificationsToSend: number[] = [];

	constructor() {
		makeAutoObservable(this);
		if (typeof window !== 'undefined') {
			this.socket = io(SOCKET_URL, { transports: ['websocket'] });
			this.subscribe();
			this.fetchNotifications().catch((e) => console.log(e));
			this.fetchStats().catch((e) => console.log(e));
		}
	}

	get notificationCount() {
		return this.notifications.filter((item) => !item.read).length;
	}

	getNotificationCountByRole = (roleId: number) =>
		+(this.stats.find((item) => item.roleId === roleId)?.notificationCount ?? 0);

	clearVisibleNotificationsToSend = () => {
		this.visibleNotificationsToSend = [];
	};

	deleteNotification = async (id: number) => {
		try {
			await backendApi.delete<TNotification[]>(`/notification/delete/${id}`);
			const filtered = this.notifications.filter((item) => item.id !== id);
			this.updateNotifications(filtered);
		} catch (e) {
			console.error('Delete message error:::', e);
		}
	};

	fetchNotifications = async () => {
		try {
			const { data } = await backendApi.get<TNotification[]>(`/notification`);
			this.updateNotifications(
				data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
			);
		} catch (e) {
			console.error('Fetch message error:::', e);
		}
	};

	fetchStats = async () => {
		try {
			const { data } = await backendApi.get<TNotificationStat[]>(`/notification/stats`);
			this.stats = data;
		} catch (e) {
			console.error('Fetch message stats error:::', e);
		}
	};

	readNotification = async () => {
		try {
			await backendApi.put<TNotification[]>(`/notification/read`, {
				id: this.visibleNotificationsToSend,
			});
			const notifications = this.notifications.map((item) => {
				if (this.visibleNotifications.includes(item.id)) {
					return { ...item, read: true };
				}
				return item;
			});
			this.updateNotifications(notifications);
			this.clearVisibleNotificationsToSend();
			this.fetchStats().catch((e) => console.log(e));
		} catch (e) {
			console.error('Read message error:::', e);
		}
	};

	subscribe = () => {
		this.socket.emit('joinRoom', String(User.id));
		this.socket.on('notification', (notifications: TNotification[]) => {
			this.updateNotifications(notifications);
			this.fetchStats().catch((e) => console.log(e));
		});
	};

	updateNotifications = (notifications: TNotification[]) => {
		this.notifications = notifications.sort(
			(a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
		);
	};

	updateVisibleNotifications = (id: number) => {
		if (!this.visibleNotifications.includes(id)) {
			this.visibleNotifications = [...this.visibleNotifications, id];
			this.visibleNotificationsToSend = [...this.visibleNotificationsToSend, id];
		}
	};
}

export default NotificationStore;

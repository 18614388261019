import React from 'react';

import { LogoutModal } from '@/features/personalAccount/views/LogoutModal';
import { Icon } from '@/ui/Icon';

const LogoutButton = () => (
	<div className="p-4">
		<LogoutModal
			trigger={
				// eslint-disable-next-line jsx-a11y/control-has-associated-label
				<button data-testid="main-button-logout" className="w-6 h-6" type="button">
					<Icon name="logout" className="text-gray-400 cursor-pointer" size={24} />
				</button>
			}
		/>
	</div>
);

export { LogoutButton };

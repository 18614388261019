import { useTranslation } from 'react-i18next';

import { Api } from '@/api';

export function useDownloadConsents() {
	const { i18n } = useTranslation();

	const handleGetDocument = (id: 1 | 2 | 3, title?: string) => Api.instance.consentsApi.getConsentDocument(id, title);

	return {
		processingConsent: () => handleGetDocument(1, i18n.t('layout.footer.Consent to processing of personal data')),
		distributionConsent: () =>
			handleGetDocument(2, i18n.t('layout.footer.Consent to the dissemination of personal data')),
		policyConsent: () => handleGetDocument(3, i18n.t('layout.footer.Policy on personal data processing')),
	};
}

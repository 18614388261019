import * as DialogPrimitive from '@radix-ui/react-dialog';
import { VariantProps } from 'class-variance-authority';
import { XIcon } from 'lucide-react';
import React from 'react';

import { textVariants } from '@/ui/Text';
import { cn } from '@/utils';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Overlay
		ref={ref}
		className={cn(
			'fixed inset-0 z-50 bg-brand-dark-blue/35 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
			className
		)}
		{...props}
	/>
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

type DialogContentProps = {
	overlayClassName?: string;
	closeable?: boolean;
};

const DialogContent = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & DialogContentProps
>(({ className, children, overlayClassName, closeable = true, ...props }, ref) => (
	<DialogPortal>
		<DialogOverlay className={overlayClassName} />
		<DialogPrimitive.Content
			ref={ref}
			className={cn(
				'font-sans fixed left-[50%] top-[50%] z-50 grid w-full max-w-2xl translate-x-[-50%] translate-y-[-50%]',
				'gap-5 px-[52px] border border-brand-gray-4 bg-white shadow-lg',
				'duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg',
				'max-h-[80vh]',
				className
			)}
			{...props}
		>
			{children}
			{closeable && (
				<DialogPrimitive.Close className="absolute -right-16 -top-4 rounded-sm opacity-70 transition-all hover:opacity-100 hover:rotate-90 disabled:pointer-events-none data-[state=open]:bg-slate-100 data-[state=open]:text-slate-500">
					<XIcon size={64} strokeWidth={1} />
					<span className="sr-only">Закрыть</span>
				</DialogPrimitive.Close>
			)}
		</DialogPrimitive.Content>
	</DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div
		className={cn('sticky inset-0 bg-white flex flex-col pt-8 pb-5 space-y-2 text-center sm:text-left', className)}
		{...props}
	/>
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div
		className={cn(
			'sticky bottom-0 bg-white pb-10 px-1 inset-x-0 flex flex-col sm:flex-row sm:justify-start sm:space-x-4 pt-5 border-t border-brand-gray-4',
			className
		)}
		{...props}
	/>
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Title>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title> & Pick<VariantProps<typeof textVariants>, 'overflow'>
>(({ className, overflow, ...props }, ref) => (
	<DialogPrimitive.Title
		ref={ref}
		className={textVariants({ variant: 'subtitle', overflow, className })}
		{...props}
	/>
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Description>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description> &
		Pick<VariantProps<typeof textVariants>, 'overflow'>
>(({ className, overflow, ...props }, ref) => (
	<DialogPrimitive.Description
		ref={ref}
		className={textVariants({ variant: 'description', overflow, className })}
		{...props}
	/>
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

const DialogNote = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
	({ className, title, children, ...props }, ref) => (
		<div
			ref={ref}
			className={cn('p-5 bg-[#F9FBFC] text-sm text-brand-dark-blue leading-tight !mt-5 rounded-xl', className)}
			{...props}
		>
			{title && <span className="block text-brand-gray-2 text-sm mb-2">{title}</span>}
			{children}
		</div>
	)
);
DialogNote.displayName = 'DialogNote';

export {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogNote,
	DialogOverlay,
	DialogPortal,
	DialogTitle,
	DialogTrigger,
};

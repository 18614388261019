import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useLogout from '@/hooks/useLogout';
import { Button } from '@/ui/Button';
import { Icon } from '@/ui/Icon';
import { Modal } from '@/ui/modals';

interface LogoutModalProps {
	trigger: React.ReactNode;
}

const LogoutModal = observer((props: LogoutModalProps) => {
	const { trigger } = props;
	const { t } = useTranslation();
	const { logOut } = useLogout();

	return (
		<Modal
			trigger={trigger}
			title={t('profile.logout_modal.title')}
			description={t('profile.logout_modal.description')}
			footer={
				<Button variant="danger" onClick={logOut} icon="left">
					<Icon name="signout" />
					{t('profile.logout_modal.submit')}
				</Button>
			}
		/>
	);
});

export { LogoutModal };

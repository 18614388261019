import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IHeaderTab } from '@/domain/core/HeaderStore';
import { ScrollArea, ScrollBar } from '@/ui/ScrollArea';

interface HeaderMenuProps {
	menuItems: IHeaderTab[];
}

const HeaderMenu = (props: HeaderMenuProps) => {
	const { menuItems } = props;
	const { pathname } = useRouter();
	const { t } = useTranslation();

	const isCurrentTabActive = (path: string) => pathname.startsWith(path);

	return (
		<div className="flex gap-2 flex-grow overflow-x-hidden">
			<ScrollArea className="whitespace-nowrap">
				<div className="flex w-max space-x-4">
					{menuItems
						.filter((item) => item.visible ?? true)
						.map((item) => (
							<Link href={item.path} key={item.name}>
								<a
									className={clsx(
										'px-4 py-6 whitespace-nowrap',
										isCurrentTabActive(item.path) ? 'text-blue-500' : 'text-gray-700'
									)}
								>
									{t(`layout.header.tabs.${item.name.trim()}`)}
								</a>
							</Link>
						))}
				</div>
				<ScrollBar orientation="horizontal" />
			</ScrollArea>
		</div>
	);
};

export { HeaderMenu };

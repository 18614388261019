import { useKeycloak } from '@react-keycloak/ssr';
import { type KeycloakInstance } from 'keycloak-js';
import { useRouter } from 'next/router';

import Preset from '@/domain/Preset/Preset';
import { removeCookie } from '@/utils/shared';

const useLogout = () => {
	const { push } = useRouter();
	const { keycloak } = useKeycloak<KeycloakInstance>();

	const onLogout = async () => {
		await Preset.deleteAllPreset();
		removeCookie('kcToken');
		removeCookie('kcIdToken');

		const logoutUrl = `${keycloak.createLogoutUrl()}&id_token_hint=${keycloak.idToken}`;
		await push(logoutUrl);
	};

	return {
		logOut: onLogout,
	};
};

export default useLogout;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDownloadConsents } from '@/hooks/useDownloadConsents';
import { Button } from '@/ui/Button';

import cn from './style.module.sass';

const Footer = () => {
	const { processingConsent, distributionConsent, policyConsent } = useDownloadConsents();
	const { t } = useTranslation();

	return (
		<footer className={cn.footer}>
			<div className={cn.left}>
				<div className={cn.title}>{t('layout.header.title')}</div>
			</div>
			<div className={cn.right}>
				<div className={cn.docs}>
					<Button
						data-testid="footer-processing-personal-data-link"
						className={cn.doc}
						variant="link"
						onClick={processingConsent}
					>
						{t('layout.footer.Consent to processing of personal data')}
					</Button>
					<Button className={cn.doc} variant="link" onClick={distributionConsent}>
						{t('layout.footer.Consent to the dissemination of personal data')}
					</Button>
					<Button className={cn.doc} variant="link" onClick={policyConsent}>
						{t('layout.footer.Policy on personal data processing')}
					</Button>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

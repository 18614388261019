import clsx from 'clsx';
import { format } from 'date-fns';
import React, { FC, useEffect, useMemo, useRef } from 'react';

import CrossIcon from '@/components/core/icons/CrossIcon';
import { useAnalytics } from '@/context/Analytics';
import { TNotificationFile } from '@/types/notification';

import cn from './style.module.sass';

interface IProps {
	id: number;
	read: boolean;
	deleteNotification: (id: number) => void;
	createdAt: string;
	notification: string;
	files: TNotificationFile[];
	addToVisibleNotification: (id: number) => void;
}

const Notification: FC<IProps> = ({
	id,
	addToVisibleNotification,
	read,
	createdAt,
	deleteNotification,
	notification,
	files,
}) => {
	const { sendEvent } = useAnalytics();
	const itemRef = useRef<HTMLDivElement>(null);
	const options = useMemo(
		() => ({
			root: itemRef.current,
			rootMargin: '0px',
			threshold: 0.5,
		}),
		[]
	);

	const callback = (entries: IntersectionObserverEntry[]) => {
		if (entries[0].isIntersecting && !read) {
			addToVisibleNotification(id);
		}
	};

	useEffect(() => {
		const observer = new IntersectionObserver(callback, options);

		if (itemRef.current) {
			observer.observe(itemRef.current);
		}

		return () => {
			if (itemRef.current) {
				observer.unobserve(itemRef.current);
			}
		};
	}, [options]);
	const handleOpenNotification = () => {
		sendEvent({ eventName: 'go_from_notifications_page' });
	};
	return (
		<div className={clsx(cn.item, read && cn.read)} ref={itemRef}>
			<div className={cn.cross} onClick={() => deleteNotification(id)} role="presentation">
				<CrossIcon />
			</div>
			<div className={cn.date}>{format(new Date(createdAt), 'dd.MM.yyyy HH:mm')}</div>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
			<div
				className={cn.notification}
				onClick={handleOpenNotification}
				dangerouslySetInnerHTML={{ __html: notification }}
			/>
			{files?.length ? (
				<>
					<div className={cn.filesTitle}>{`Вложени${files?.length > 1 ? 'я' : 'е'}:`}</div>
					{files.map(({ url, name, id }) => (
						<div key={id}>
							<a href={url} target="_blank" rel="noreferrer">
								{name}
							</a>
						</div>
					))}
				</>
			) : null}
		</div>
	);
};

export default Notification;

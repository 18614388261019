import { observer } from 'mobx-react-lite';
import React, { FC, ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLockBodyScroll } from 'react-use';

import SidebarModal from '@/components/core/modal/SidebarModal';
import Notification from '@/components/features/notification/Notification';
import { useAnalytics } from '@/context/Analytics';
import NotificationStoreContext from '@/context/NotificationStoreContext';
import { TNotificationStore } from '@/domain/notification/NotificationStore';
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback';

import cn from './style.module.sass';

interface IProps {
	open: boolean;
	handleClose: () => void;
	handleOpen: () => void;
	children: ReactElement;
}

const NotificationSidebar: FC<IProps> = observer(({ open, handleClose, handleOpen, children }) => {
	const { sendEvent } = useAnalytics();
	const { t } = useTranslation();
	const { notifications, deleteNotification, notificationCount, updateVisibleNotifications, readNotification } =
		useContext<TNotificationStore>(NotificationStoreContext);
	useLockBodyScroll(open);
	const debouncedReadNotification = useDebouncedCallback(() => readNotification(), 1000);

	const handleModalOpen = () => {
		sendEvent({ eventName: 'visit_notifications_page' });

		handleOpen();
	};

	return (
		<>
			<div
				data-testid="main-button-notifications"
				className="relative w-6 h-6"
				onClick={handleModalOpen}
				role="presentation"
			>
				{children}{' '}
				{notificationCount > 0 && (
					<span className={cn.unread}>{notificationCount < 100 ? notificationCount : '99+'}</span>
				)}
			</div>
			<SidebarModal isOpen={open} onCloseHandler={handleClose} handleScroll={debouncedReadNotification}>
				<div className={cn.wrapper}>
					<div className={cn.title}>{t('Уведомления')}</div>
					<div className={cn.list}>
						{notifications.length > 0 &&
							notifications.map(({ id, createdAt, notification, read, files }) => (
								<Notification
									id={id}
									addToVisibleNotification={updateVisibleNotifications}
									read={read}
									deleteNotification={deleteNotification}
									createdAt={createdAt}
									notification={notification}
									files={files}
									key={id}
								/>
							))}
					</div>
				</div>
			</SidebarModal>
		</>
	);
});

export default NotificationSidebar;

import Head from 'next/head';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type PageHeadProps = {
	title?: string;
};

const PageHead: FC<PageHeadProps> = ({ title }) => {
	const { t } = useTranslation();
	return (
		<Head>
			<title>{title || t('layout.header.title')}</title>
			<link rel="icon" href="/favicon.ico" />
		</Head>
	);
};

export default PageHead;
